.headerMainText {
  color: white;
}

.headerSubText {
  color: white;
  letter-spacing: 2px;
}

.headerTextContainer {
  z-index: 1;
}

/* desktop/large screens */
@media only screen and (min-width: 1025px) {
  #headerDiv {
    display: flex;
    justify-content: flex-end;
    background-color: maroon;
    height: 490px;
  }

  .headerTextContainer {
    position: absolute;
    top: 100px;
    left: 14%;
    width: 600px;
  }

  .carousel {
    position: absolute;
    height: auto;
    max-width: 40%;
    width: 100%;
    top: 90px;
    right: 10vw;
  }

  #loginNavBtn {
    border-color: white;
  }

  #learnMoreHeaderBtn {
    margin-left: 60px;
  }

  #learnMoreHeaderBtn:hover {
    border-color: white;
    background-color: maroon;
  }

  img {
    border-radius: 6%;
  }

  .headerMainText {
    font-size: 4rem;
    width: 70%;
  }

  .headerSubText {
    margin-top: 25px;
    font-size: 1.5rem;
    line-height: 35px;
    font-weight: 300;
  }

  #headerDemoBtn {
    position: relative;
    left: 70%;
    top: 10px;
  }

  #logEventInBtn {
    display: none;
  }
}

/* small screens/laptops */
@media only screen and (min-width: 830px) and (max-width: 1024px)  and (max-height: 999px){
  #headerDiv {
    display: flex;
    justify-content: flex-end;
    background-color: maroon;
    height: 490px;
  }

  .headerTextContainer {
    position: absolute;
    top: 100px;
    left: 10%;
    width: 400px;
  }

  .carousel {
    position: absolute;
    height: auto;
    max-width: 40%;
    width: 100%;
    top: 140px;
    right: 10vw;
  }

  #loginNavBtn {
    border-color: white;
  }

  #learnMoreHeaderBtn {
    margin-left: 60px;
  }

  #learnMoreHeaderBtn:hover {
    border-color: white;
    background-color: maroon;
  }

  img {
    border-radius: 6%;
  }

  .headerMainText {
    font-size: 3rem;
    width: 70%;
  }

  .headerSubText {
    margin-top: 25px;
    font-size: 1.3rem;
    line-height: 35px;
    font-weight: 300;
  }

  #headerDemoBtn {
    position: relative;
    left: 70%;
    top: 10px;
  }

  #logEventInBtn {
    display: none;
  }
}

/* ipad */
@media only screen and (min-width: 481px) and (max-width: 1024px) and (min-height: 800px){

  #headerDiv {
    display: flex;
    justify-content: flex-end;
    background-color: maroon;
    height: 40vh;
  }

  img {
    border-radius: 6%;
  }

  .carousel {
    position: absolute;
    height: auto;
    max-width: 53%;
    width: 100%;
    top: 100px;
    right: 3vw;
  }

  .headerTextContainer {
    position: absolute;
    top: 10vh;
    left: 3vw;
    width: 400px;
    font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  }


  .headerMainText {
    font-size: 3rem;
    width: 70%;
  }

  .headerSubText {
    margin-top: 25px;
    font-size: 1.3rem;
    line-height: 35px;
  }

  #headerDemoBtn {
    position: relative;
    left: 70%;
    top: 10px;
  }

  #learnMoreHeaderBtn {
    margin-left: 70px;
  }

  #logEventInBtn {
    display: none;
  }
}

/* mobile up right */
@media only screen and (max-width: 767px) {

  #headerDiv {
    display: flex;
    justify-content: flex-end;
    background-color: maroon;
    height: 250px;
  }

  .carousel {
    display: none;
  }

  .headerTextContainer {
    position: absolute;
    top: 80px;
    margin-right: 125px;
    font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  }

  .headerMainText {
    font-size: 1.5rem;
  }

  #headerDemoBtn {
    position: relative;
    left: 45%;
    top: 10px;
    padding: 5px 10px;
    font-size: 1.2rem;
  }

  #logEventInBtn {
    display: none;
  }

  #learnMoreHeaderBtn {
    float: right;
    margin-right: -80px;
    margin-top: -25px;
  }

}

/* mobile sideways */
@media only screen and (min-width: 550px) and (max-width: 766px) {
  .headerTextContainer {
    position: absolute;
    top: 110px;
    margin: 0px 20px;
    font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  }
  .headerSubText {
    display: block;
    margin-top: 25px;
    font-size: 1.5em;
    line-height: 35px;
    color: #1111;
  }
  #headerDemoBtn {
    position: relative;
    left: 40%;
    top: 10px;
  }
}

/* stupid iphone 5 */
@media only screen and (max-width: 319px) {
  #headerDemoBtn {
    position: relative;
    left: 55%;
    top: 10px;
  }
  #headerTextContainer {
    position: relative;
    left: -10px;
  }
}