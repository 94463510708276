@media print {
  * {
    page-break-inside: avoid !important; 
    page-break-before: avoid !important;
  }
  .hide-on-print {
    display: none !important;
  }

  .hide-on-non-print {
    display: block !important;
  }

  .logInInputField {
    break-inside: avoid;
    margin: 1px;
  }

  .print-container {
    display: flex;
    flex-wrap: wrap;
  }

  .print-column {
    flex: 1 0 33.33%;
    box-sizing: border-box;
    padding: 10px;
  }

  .input-header {
    margin-top: 15px;
  }

  .meta-details-content {
    margin: 0px !important;
  }

  .sigSection {
    page-break-after: always !important;
  }

  #createDate {
    border: none;
    display: inline-block;
    text-align: left;
    width: 250px
  }
  
  #summary_of_daily_schedule-row {
    margin-top: -30px;
  }
}

#sigCanvasDiv {
  height: 100px;
  display: flex;
  padding-bottom: 20px;
}

.sigSection {
  padding-left: 15px;
}

td{
  width: 20%;
}

.hide-on-non-print {
  display: none;
}

.meta-details-content {
  margin: 0px 20px 0px 20px;
}

input {
  height: 40px;
  border-radius: 9px;
}

.formLoadingDiv {
  text-align: center;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btn-light {
  background-color: #5f000026;
  border-color: black;
}

.btn-red {
  background-color: #5f000026;
  border-color: black;
}

.btn-red:hover {
  background-color: #FF1744; 
  border-color: #5f000026;
  color: #fff;
}

.btn-green {
  background-color: #5f000026;
  border-color: black;
}

.btn-green:hover {
  background-color: #00E676; 
  border-color: #5f000026;
  color: #fff;
}

.save-submit-btn {
  width: 40%;
}

.save-submit-btn:disabled, 
.save-submit-btn:disabled:hover {
  background-color: #cccccc;
  color: #666666;
}

.save-submit-row {
  display: flex;
  justify-content: space-between;
  padding-right: 0px;
  margin-left: 1px;
  margin-right: 1px;
  padding-bottom: 20px;
}

.formSpinner {
  display: block;
  margin-left: 10px;
}

.defaultLabel {
  color: maroon;
  border-bottom: 0.5px solid #eee;
  padding: 10px 10px;
}

.nav-link-not-logged-in.active {
  background-color: transparent !important;
}

.modal-content {
  border: none;
  background-color: transparent;
}

.modal-content.demo {
  border: none;
  background-color: #f5f5f5 !important;
}

.nav-link-not-logged-in {
  background-color: maroon !important;
  border-color: white;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.nav-link-not-logged-in:hover {
  background-color: white !important;
  color: maroon !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* .nav-link-not-logged-in-fill {
  background-color: maroon !important;
  color: white !important;
  border: solid 0.5px maroon !important;
}

.nav-link-not-logged-in-fill:hover {
  color: maroon !important;
  background-color: white !important;
  border: solid 0.5px white !important;
} */

.nav-link.active {
  background-color: #5e0000;
  border-radius: 11px;
}

/* .sigCanvas {
  width: 300px;
  padding-bottom: 0px;
  height: 50px;
} */

.extraInfoMainTitle {
  font-weight: 300;
}

.extraInfoSubTitle {
  font-weight: 200;
}

.extraInfoNavDiv {
  margin: 20px 0px;
}

.extraInfoNavTitle {
  color: maroon;
  font-weight: 300;
}

.extraInfoButtonDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
}

.extraInfoButton {
  text-align: left;
}

.defaultSubLabel {
  font-weight: 900;
  /* margin2px; */
}

.flexNewPassword {
  display: flex;
}

label {
  font-weight: 300 !important;
}

.formFields {
  margin: 25px 10px;
  height: fit-content;
}

.formFieldsTitle {
  margin: 15px 0px;
}

.pointer {
  cursor: pointer;
}

.fa {
  position: inherit;
}

.modal-header {
  display: flex;
}

.modal-header>h5 {
  flex: 1;
}

.dropdown-toggle {
  color: white !important;
}

.lightBtn {
  background-color: white;
  color: maroon;
  font-weight: 800;
  letter-spacing: 1px;
  border-radius: 15px;
  font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  padding: 10px 20px;
  transition: all 0.3s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.mainFont {
  font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
}

.lightBtn:hover {
  background-color: maroon;
  color: white;
  font-weight: 800;
  letter-spacing: 1px;
  border-radius: 15px;
  font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  padding: 10px 20px;
  transition: all 0.3s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.lightBtnSimple {
  background-color: white;
  color: maroon;
  font-weight: 800;
  letter-spacing: 1px;
  border-radius: 15px;
  font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  padding: 10px 20px;
  transition: all 0.3s;
}

.darkBtn {
  background-color: maroon;
  color: white;
  font-weight: 800;
  letter-spacing: 1px;
  border-radius: 15px;
  font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  padding: 10px 20px;
  transition: all 0.3s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.darkBtn:hover {
  background-color: white;
  color: maroon;
  font-weight: 800;
  letter-spacing: 1px;
  border-radius: 15px;
  font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  padding: 10px 20px;
  transition: all 0.3s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.darkBtnSimple {
  background-color: maroon;
  color: white;
  font-weight: 800;
  letter-spacing: 1px;
  border-radius: 15px;
  font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  padding: 10px 20px;
  transition: all 0.3s;
}

.clearBtn {
  background-color: transparent;
  text-shadow: 0px 2px 6px #707070e7;
  font-weight: 800;
  letter-spacing: 1px;
  border-radius: 15px;
  font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  padding: 10px 20px;
  transition: all 0.3s;
}

.clearOGBtn {
  background-color: transparent;
  font-weight: 800;
  letter-spacing: 1px;
  border-radius: 15px;
  font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  padding: 8px 10px 5px 10px;
  transition: all 0.3s;
}

.textAreaSmall {
  width: 100%;
  border-radius: 9px;
  resize: none;
  height: 100px;
  border: 1px solid #ccc;
}

.hideIt {
  display: none;
}

.showWebinarDetail {
  margin-left: 25px;
}

.modelBtn {
  margin: 0px 5px;
}

body {
  background-color: white;
}

.App {
  overflow-x: hidden;
}

#extraInfo {
  position: fixed;
  width: 20%;
  top: 15%;
  left: 12%;
}

.filterSection {
  margin: 15px 65px 15px 65px;
}

html {
  scroll-behavior: smooth;
}

/* desktop */
@media only screen and (min-width: 1226px) {
  .mobileLearnMore {
    display: none;
  }

  .formComp {
    padding: 0px 20px 1px 20px;
    margin: 0px 20px 0px 20px;
    background-color: #f1f1f182;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }

  .formCompNoBg {
    padding: 0px 20px 1px 20px;
    margin: 0px 20px 0px 20px;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }

  .formTitleDivReport {
    color: maroon;
    min-height: 60px;
    margin-bottom: 25px;
    padding: 25px 0px 10px 0px;
    border-bottom: 0.5px solid #cfcece;
  }

  .messageBoardTitleDiv {
    color: maroon;
    min-height: 60px;
    margin-bottom: 25px;
    padding: 25px 0px 10px 0px;
  }

  .alert {
    position: fixed;
    width: 900px;
    height: auto;
    margin: 5% auto;
    left: 0;
    right: 0;
    max-height: 500px;
    overflow: scroll;
  }

  #desktopView {
    margin-top: 60px;
  }

  #mobileView {
    display: none;
  }

  #mainContainer {
    /* height: 500px; */
    /* margin: 0px 250px; */
    width: 75%;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  #greetingContainer {
    padding: 0px;
    border-bottom: 15px solid maroon;
    padding-bottom: 150px;
  }

  #greetingRow {
    margin: 100px 20px 0px 20px;
  }

  #greetingRowRight {
    margin-top: -20px;
  }

  #greetingRowRightHeader {
    font-size: 3.5em;
    letter-spacing: 2px;
    line-height: 70px;
    color: maroon;
    font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
    /* text-shadow: 0px 1px 2px #707070e7; */
  }

  #greetingRowRightPContainer {
    margin-right: 90px;
  }

  #greetingRowRightP {
    font-size: 1.8em;
    letter-spacing: 1px;
    line-height: 40px;
    font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  }

  #greetingRowRightBtnContainer {
    margin-top: 5vh;
    display: flex;
    justify-content: flex-start;
    margin-right: 150px;
  }

  .formTitleDiv {
    color: maroon;
    min-height: 60px;
    margin-bottom: 25px;
    padding: 25px 0px 10px 0px;
    border-bottom: 0.5px solid #cfcece;
  }

  .messageBoardTitleDiv {
    color: maroon;
    min-height: 60px;
    margin-bottom: 25px;
    padding: 25px 0px 10px 0px;
  }

  .formTitle {
    font-size: 2.3em;
    text-align: center;
  }

  #homeiPhone {
    background: url("../src/images/iphone.gif") no-repeat;
    background-size: contain;
    height: 600px;
    width: 500px;
    margin-left: 50px;
  }

  .userContainer {
    background-color: white;
    padding: 10px 0px;
    width: 100%;
  }

  .hideOnDesktop {
    display: none !important;
  }

  #navTitleDesktop {
    width: 100%;
    color: maroon;
    font-weight: 900;
    font-size: 1.22em;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    position: fixed;
    background-color: white;
    padding: 50px 0px 8px 0px;
    border-bottom: 0.5px solid rgb(202, 202, 202);
    z-index: 50;
  }

  #navTitleDesktop>p {
    margin-left: 20px;
  }

  #navActionContainer {
    margin-top: 35px;
    position: fixed;
  }

  #navActionGreeting {
    font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
    font-size: 2em;
  }

  #summary_of_daily_schedule-row {
    margin-top: -30px;
  }
}

/* ipad */
@media only screen and (min-width: 481px) and (max-width: 1226px){
  .mobileLearnMore {
    display: none;
  }

  .formComp {
    padding: 0px 20px 1px 20px;
    margin: 0px 20px 0px 20px;
    background-color: #f1f1f182;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }

  .formCompNoBg {
    padding: 0px 20px 1px 20px;
    margin: 0px 20px 0px 20px;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }

  .formTitleDivReport {
    color: maroon;
    min-height: 60px;
    margin-bottom: 25px;
    margin-top: 0px;
    padding: 25px 0px 10px 0px;
    border-bottom: 0.5px solid #cfcece;
  }

  .messageBoardTitleDiv {
    color: maroon;
    min-height: 60px;
    margin-bottom: 25px;
    padding: 25px 0px 10px 0px;
  }

  #extraInfo {
    position: fixed;
    width: 23%;
    top: 20%;
    left: 35px;
  }

  .formTitleDiv {
    color: maroon;
    min-height: 60px;
    margin-bottom: 10px;
    padding: 25px 0px 10px 0px;
    border-bottom: 0.5px solid #cfcece;
  }

  .messageBoardTitleDiv {
    color: maroon;
    min-height: 60px;
    margin-bottom: 25px;
    padding: 25px 0px 10px 0px;
  }

  .formTitle {
    font-size: 2.3em;
    text-align: center;
  }

  .alert {
    position: fixed;
    width: 600px;
    height: auto;
    margin: 5% auto;
    left: 0;
    right: 0;
    max-height: 500px;
    overflow: scroll;
  }

  #desktopView {
    margin-top: 60px;
  }

  #navTitleDesktop {
    z-index: 50;
    color: maroon;
    font-weight: 900;
    font-size: 1.2em;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    position: fixed;
    width: inherit;
    background-color: white;
    padding: 50px 0px 8px 0px;
    border-bottom: 0.5px solid rgb(202, 202, 202);
  }

  #navTitleDesktop>p {
    margin-left: 20px;
  }

  #navActionContainer {
    margin-top: 35px;
    position: fixed;
  }

  #navActionGreeting {
    font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
    font-size: 2em;
  }

  .hideOnDesktop {
    display: none !important;
  }

  #mobileView {
    display: none;
  }

  #greetingContainer {
    padding: 0px;
    border-bottom: 15px solid maroon;
    padding-bottom: 50px;
  }

  #greetingRow {
    margin: 50px 50px 0px 50px;
  }

  #greetingRowRightHeader {
    font-size: 3em;
    letter-spacing: 2px;
    line-height: 60px;
    color: maroon;
    font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  }

  #greetingRowRightPContainer {
    margin-right: 20px;
  }

  #greetingRowRightP {
    font-size: 1.2em;
    letter-spacing: 1px;
    line-height: 40px;
    font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  }

  #greetingRowRightBtnContainer {
    margin-top: 20px;
    display: flex;
  }

  #homeiPhone {
    margin-top: 80px;
    background: url("../src/images/iphone.gif") no-repeat;
    background-size: contain;
    height: 400px;
    width: 300px;
    position: relative;
  }

  #summary_of_daily_schedule-row {
    margin-top: -30px;
  }
}

/* mobile up right */
@media only screen and (max-width: 767px) {
  .logInContainer {
    position: fixed !important;
    top: 20px !important;
  }

  .mobileAdj {
    position: fixed !important;
    top: 20px !important;
    left: 0px !important;
  }


  .filterSection {
    margin: 15px 0px 15px 0px;
  }

  .formTitleDiv {
    -webkit-box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.75);
    color: maroon;
    background-color: #f1f1f1;
    position: fixed;
    width: -webkit-fill-available;
    min-height: 60px;
    padding: 10px 0px;
    left: 0;
    right: 0;
    top: -20px;
    margin: 75px 0px;
    z-index: 1;
  }

  .messageBoardTitleDiv {
    /* -webkit-box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.75); */
    color: maroon;
    background-color: #f1f1f1;
    /* position: fixed; */
    width: -webkit-fill-available;
    min-height: 60px;
    padding: 10px 0px;
    /* left: 0;
    right: 0; */
    top: -20px;
    margin: 75px 0px;
    z-index: 1;
    border-bottom: 0.5px solid #eee;
  }

  .formTitleDivReport {
    color: maroon;
    background-color: rgba(255, 255, 255, 0.733);
    position: fixed;
    width: -webkit-fill-available;
    min-height: 60px;
    padding: 20px 0px;
    left: 0;
    right: 0;
    top: 100px;
    margin: 75px 0px;
    z-index: 0;
    text-align: center;
    border-bottom: 0.5px solid #ccc;
  }

  .formComp {
    margin: 0px 20px 1px 20px;
  }

  .formCompNoBg {
    margin: 0px 20px 1px 20px;
  }

  #extraInfo {
    display: none;
    transition: all 2s;
  }

  .formTitle {
    font-size: 1.8em;
    margin: 5px 15px;
    text-align: center;
  }

  .reportBtnsMobile {
    margin-top: 175px;
  }

  .formFieldsMobile {
    margin-top: 100px;
  }

  .formFieldsMobileReport {
    margin-top: 50px;
  }

  html {
    scroll-behavior: smooth;
  }

  .alert {
    position: fixed;
    width: 300px;
    top: 40%;
    height: auto;
    margin: 5% auto;
    left: 0;
    right: 0;
    z-index: 1;
    max-height: 500px;
    overflow: scroll;
  }

  .alert-heading {
    font-size: 1.3em;
  }

  #desktopView {}

  #greetingContainer {
    padding: 0px;
    border-bottom: 15px solid maroon;
    padding-bottom: 20px;
  }

  #greetingColRight {
    padding: 0px;
  }

  #greetingRow {
    margin: 20px 20px 0px 20px;
  }

  #greetingRowRightHeader {
    font-size: 2em;
    letter-spacing: 1px;
    line-height: 40px;
    color: maroon;
    font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  }

  #greetingRowRightP {
    font-size: 1.2em;
    letter-spacing: 1.5px;
    line-height: 30px;
    font-family: "Google Sans Display", Arial, Helvetica, sans-serif;
  }

  #greetingRowRightBtnContainer {
    margin-top: 0px;
    display: flex;
    justify-content: center;
  }

  #iPhoneContainer {
    display: flex;
    justify-content: center;
    height: 200px;
  }

  #homeiPhone {
    /* margin-top: -90px; */
    background: url("../src/images/iphone.gif") no-repeat;
    background-size: contain;
    height: 200px;
    width: 100px;
    margin-top: 0px;
  }

  .modal-dialog {
    margin-top: 100px;
  }
}

/* mobile sideways */
@media only screen and (min-width: 550px) and (max-width: 766px) {
  .desktopLearnMore {
    display: none;
  }

  html {
    scroll-behavior: smooth;
  }

  .alert-heading {
    font-size: 1.3em;
  }

  #greetingRowRightPContainer {
    margin-right: 200px;
  }

  #iPhoneContainer {
    height: 0px;
  }

  #homeiPhone {
    position: relative;
    top: -250px;
    left: -10%;
    background: url("../src/images/iphone.gif") no-repeat;
    background-size: contain;
    height: 200px;
    width: 100px;
  }
}

/* stupid iphone 5 */
@media only screen and (max-width: 361px) {
  .desktopLearnMore {
    display: none;
  }

  html {
    scroll-behavior: smooth;
  }

  #homeiPhone {
    position: relative;
    left: 10px;
    background: url("../src/images/iphone.gif") no-repeat;
    background-size: contain;
    height: 200px;
    width: 100px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}