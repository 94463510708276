.formnameclass {
  color: black;
  padding: "10px";
  border-radius: 9px;
  background: white;
  cursor: pointer;
  font-weight: 900;
  border: 1px solid rgb(128, 0, 0);
  font-size: 0.8em;
}

.formnameclass:hover {
  color: black;
  padding: "10px";
  border-radius: 9px;
  background: rgb(225, 225, 225);
  cursor: pointer;
  font-weight: 900;
  border: 1px solid rgb(128, 0, 0);
  font-size: 0.8em;
}
